import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate key`}</strong>{` -- print public key embedded in a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate key <crt-file> [--out=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate key`}</strong>{` prints the public key embedded in a certificate or
a certificate signing request. If `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` is a certificate bundle, only the
first block will be taken into account.`}</p>
    <p>{`The command will print a public or a decrypted private key if `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{`
contains only a key.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Path to a certificate or certificate signing request (CSR).`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--output-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The destination `}<inlineCode parentName="p">{`file`}</inlineCode>{` of the public key.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Get the public key of a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate key certificate.crt
-----BEGIN PUBLIC KEY-----
MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEio9DLyuglMxakS3w00DUKdGbeXXB
2Mfg6tVofeXYan9RbvftZufiypIAVqGZqO7CR9EbkoyHb/7GcKQa5HZ9rA==
-----END PUBLIC KEY-----
`}</code></pre>
    <p>{`Get the public key of a CSR and save it to a file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate key certificate.csr --out key.pem
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      